/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [  
  {
    url: null,
    name: "asdf",
    userMenu: true,
    slug: "member",
    icon: "UserIcon",
    levelUp: '0',
    levelDw: '10',
    submenu: [
      // {
      //   url: '/myPage',
      //   name: "마이 페이지",
      //   slug: "myPage",
      // },
      {
        url: '/pages/logout',
        name: "로그아웃",
        slug: "logout",
      },
    ]
  },
  {
    url: "/WriteArticles",
    name: "기사작성",
    slug: "writearticles",
    icon: "CreditCardIcon",
    levelUp: '2',
    levelDw: '10',
  },
  {
    url: "/newsList",
    name: "뉴스리스트",
    slug: "newslist",
    icon: "ListIcon",
    levelUp: '2',
    levelDw: '10',
    submenu: [
      {
        url: '/newsListAll',
        name: '전체',
        slug: "newslist",
      },
      {
        url: '/newsListChem',
        name: '화학',
        slug: "newslist",
      },
      {
        url: '/newsListBada',
        name: '조선/물류',
        slug: "newslist",
      },
      {
        url: '/newsListSteel',
        name: '철강',
        slug: "newslist",
      },
      // {
      //   url: '/dailianList',
      //   name: '데일리안',
      //   slug: "dailianNewsList",
      // },
      {
        url: '/yonhapList',
        name: '연합',
        slug: "yonhapNewsList",
      },
    ]
  },
  {
    url: "/photoList",
    name: "포토리스트",
    slug: "photolist",
    icon: "GridIcon",
    levelUp: '2',
    levelDw: '10',
    submenu: [
      {
        url: '/photoArticle',
        name: '기사사진',
        slug: "articlePhotos",
      },
      // {
      //   url: '/photoDB',
      //   name: '데일리안 포토DB',
      //   slug: "photosDB",
      // },
    ]
  },
  // {
  //   url: "/NewsPlace",
  //   name: "뉴스배치",
  //   slug: "newsplace",
  //   icon: "LayoutIcon",
  //   levelUp: '8',
  //   levelDw: '10',
  //   submenu: [
  //     {
  //       url: '/NewsPlace?category=0',
  //       name: '메인 배치',
  //       slug: "newsplace",
  //     },
  //     {
  //       url: '/NewsPlace?category=1',
  //       name: '전자 배치',
  //       slug: "newsplace_1",
  //     },
  //     {
  //       url: '/NewsPlace?category=2',
  //       name: '자동차 배치',
  //       slug: "newsplace_2",
  //     },
  //     {
  //       url: '/NewsPlace?category=3',
  //       name: '화학 배치',
  //       slug: "newsplace_3",
  //     },
  //     {
  //       url: '/NewsPlace?category=4',
  //       name: '철강 배치',
  //       slug: "newsplace_4",
  //     },
  //     {
  //       url: '/NewsPlace?category=5',
  //       name: '조선/물류 배치',
  //       slug: "newsplace_5",
  //     },
  //     {
  //       url: '/NewsPlace?category=6',
  //       name: '건설 배치',
  //       slug: "newsplace_6",
  //     },
  //     {
  //       url: '/NewsPlace?category=7',
  //       name: '금융 배치',
  //       slug: "newsplace_7",
  //     },
  //     {
  //       url: '/NewsPlace?category=8',
  //       name: '증권 배치',
  //       slug: "newsplace_8",
  //     },
  //     {
  //       url: '/NewsPlace?category=9',
  //       name: '유통 배치',
  //       slug: "newsplace_9",
  //     },
  //     {
  //       url: '/NewsPlace?category=10',
  //       name: '사회 배치',
  //       slug: "newsplace_10",
  //     },
  //     {
  //       url: '/NewsPlace?category=11',
  //       name: 'IT 배치',
  //       slug: "newsplace_11",
  //     },
  //     {
  //       url: '/NewsPlace?category=99',
  //       name: "사고, 공고 배치",
  //       slug: "newsplace_notice",
  //     },
  //   ]
  // }, 
  {
    url: "/NewsPlace",
    name: "새 카테고리 뉴스배치",
    slug: "newsplace",
    icon: "LayoutIcon",
    levelUp: '8',
    levelDw: '10',
    submenu: [
      {
        url: '/NewsPlace?category=100',
        name: '메인 배치',
        slug: "newsplace",
      },
      {
        url: '/NewsPlace?category=101',
        name: '전자·ICT 배치',
        slug: "newsplace_1",
      },
      {
        url: '/NewsPlace?category=102',
        name: '금융·증권 배치',
        slug: "newsplace_2",
      },
      {
        url: '/NewsPlace?category=103',
        name: '건설 배치',
        slug: "newsplace_3",
      },
      {
        url: '/NewsPlace?category=104',
        name: '자동차 배치',
        slug: "newsplace_4",
      },
      {
        url: '/NewsPlace?category=105',
        name: '철강·조선 배치',
        slug: "newsplace_5",
      },
      {
        url: '/NewsPlace?category=106',
        name: '에너지 배치',
        slug: "newsplace_6",
      },
      {
        url: '/NewsPlace?category=107',
        name: '유통 배치',
        slug: "newsplace_7",
      },
      {
        url: '/NewsPlace?category=99',
        name: "사고, 공고 배치",
        slug: "newsplace_notice",
      },
    ]
  }, 
  {
    url: null,
    name: "게시판",
    slug: "board",
    icon: "FileTextIcon",
    levelUp: '4',
    levelDw: '10',
    submenu: [
      {
        url: '/board?tbcode=101',
        name: "정보보고",
        slug: "bd_bogo",
      },
      {
        url: '/board?tbcode=102',
        name: "사내게시판",
        slug: "bd_freebd",
      },
      {
        url: '/board?tbcode=103',
        name: "제안게시판",
        slug: "bd_suggest",
      },
      {
        url: '/Calendar',
        name: "야근일지",
        slug: "bd_overTime",
      },
      {
        url: '/RequestingTimeOff',
        name: "휴가신청",
        slug: "bd_TimeOff",
      },
    ]
  },
  {
    url: null,
    name: "회원관리",
    slug: "member",
    icon: "UserIcon",
    // levelUp: '9',
    levelUp: '10',
    levelDw: '11',
    submenu: [
      {
        url: '/member/reporter?deleted=true',
        name: "퇴사자 관리",
        slug: "memberReporter",
      },
      {
        url: '/member/reporter?deleted=',
        name: "기자 관리",
        slug: "memberReporter",
      },
    ]
  },
  {
    url: null,
    name: "구독자관리",
    slug: "member2",
    icon: "UserIcon",
    // levelUp: '9',
    levelUp: '9',
    levelDw: '11',
    submenu: [
      {
        url: '/member/individual',
        name: "개인 회원(통합)",
        slug: "memberIndividual",
      },
      {
        url: '/member/company',
        name: " 기업 회원(통합)",
        slug: "memberCompany",
      },
      {
        url: '/member/common',
        name: "승인대기",
        slug: "memberCommon",
      },
    ]
  },
  {
    name: "통계",
    slug: "statistics",
    icon: "PieChartIcon",
    levelUp: '7',
    levelDw: '12',
    submenu: [
      {
        url: '/statistics/desc',
        name: "조회수 순",
        slug: "statisticsDesc",
      },
      {
        url: '/statistics/eachReporter',
        name: "작성자별",
        slug: "statisticsEachReporter",
      },
    ]
  },
  // {
  //   url: "/statistics/myOwn",
  //   name: "통계",
  //   slug: "statisticsMyOwn",
  //   icon: "PieChartIcon",
  //   levelUp: '7',
  //   levelDw: '12',
  // },
  {
    name: "배너",
    slug: "adBanners",
    icon: "LayoutIcon",
    levelUp: '10',
    levelDw: '12',
    submenu: [
      {
        url: '/ad/member',
        name: "광고사 관리",
        slug: "memberAd",
      },
      {
        url: '/ad/mains',
        name: "메인영역",
        slug: "adMains",
      },
      {
        url: '/ad/sides',
        name: "사이드바",
        slug: "adSides",
      },
      {
        url: '/ad/header',
        name: "상단",
        slug: "adHeader",
      },
      {
        url: '/ad/footer',
        name: "하단",
        slug: "adFooter",
      },
    ]
  },
  {
    url: "/Setting",
    name: "설정",
    slug: "setting",
    icon: "SettingsIcon",
    levelUp: '10',
    levelDw: '12',
  },
  {
    url: '/bannerManager/main',
    name: "메인영역",
    slug: "adMains",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/sidebar',
    name: "사이드바",
    slug: "adSides",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/header',
    name: "상단",
    slug: "adHeader",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/footer',
    name: "하단",
    slug: "adFooter",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: "http://node.ebn.co.kr",
    name: "메인 웹페이지",
    slug: "external",
    target: "blank",
    icon: "HomeIcon",
    levelUp: '1',
    levelDw: '10',
  },
]
